<template>
    <div>
        <div
            v-if="isLightbox"
            class="h-[35px]"
        />

        <div
            class="bg-gray-100 py-2 text-center text-gray-600"
            :class="isLightbox ? 'fixed bottom-0 left-0 right-0 z-20' : ''"
        >
            Powered by
            <nuxt-link
                to="https://www.redpointhq.com"
                target="_blank"
                class="font-semibold hover:underline"
                >RedpointHQ</nuxt-link
            >&nbsp;<span class="text-xs text-gray-300"
                >&mdash;&nbsp;{{ version.major }}.{{ version.minor }}.{{
                    version.patch
                }}</span
            >
        </div>
    </div>
</template>

<script setup lang="ts">
import { version } from '~/composables/meta'
import NuxtLink from '#app/components/nuxt-link'
import { useLayoutMode } from '~/composables/use-layout-mode'

const { isLightbox } = useLayoutMode()
</script>
